<template>
  <div class="message-section">
    <p class="message-section__headline" v-if="headline">{{ headline }}</p>
    <div class="message-section__text" v-if="text">
      <p>{{ text }}</p>
      <div class="message-section__link" v-if="link">
        <Button :to="link" :onclick="fn" :value="linkText" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
export default {
  name: 'Message',
  components: {
    Button
  },
  props: {
    headline: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    link: {
      required: false,
      type: String
    },
    linkText: {
      required: false,
      type: String
    },
    fn: {
      required: false,
      type: Function
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.message-section {
  text-align: center;
}

.message-section__headline {
  text-transform: uppercase;
  font-family: $accent-font;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1.2px;
}

.message-section__text {
  margin: 0 auto 20px;
  max-width: 280px;
}

.message-section__link {
  .button {
    min-width: 200px;
  }
}
</style>
