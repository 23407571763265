<template>
  <div class="tip">
    <div v-if="back && !backTo && !hideBack" class="tip__link">
      <button v-on:click="goBack" type="button">
        <ArrBack />
      </button>
    </div>
    <div v-if="backFn && !hideBack" class="tip__link">
      <button :onclick="backFn" type="button">
        <ArrBack />
      </button>
    </div>
    <div v-if="backTo && !hideBack" class="tip__link">
      <router-link :to="backTo">
        <ArrBack />
      </router-link>
    </div>
    <div class="tip__text">
      {{ text }}
    </div>
    <div v-if="forth" class="tip__link">
      <router-link :to="forth">
        <ArrForth />
      </router-link>
    </div>
  </div>
</template>

<script>
import ArrBack from '@/assets/images/arr-left.svg?inline'
import ArrForth from '@/assets/images/arr-right.svg?inline'
export default {
  name: 'Tip',
  components: {
    ArrBack,
    ArrForth
  },
  props: {
    backFn: {
      type: Function
    },
    text: {
      type: String
    },
    skip: {
      type: String
    },
    back: {
      required: false,
      type: Boolean
    },
    hideBack: {
      required: false,
      type: Boolean
    },
    backTo: {
      required: false
    },
    forth: {
      required: false,
      type: String
    },
    andHideOverlay: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    goBack: function () {
      this.andHideOverlay && ARController.hideVeryOverlay();
      router.go(-1);
      if(this.skip){
        var skip = this.skip;
        setTimeout( function(){
          if( StateController.isSameHash( location.hash, skip ) ){
            router.go( -1 );
            setTimeout( function(){
              if( StateController.isSameHash( location.hash, skip ) ){
                router.go( -1 );
              }
            }, 30 );
          }
        }, 30 );
      }
    }
  }
}
</script>

<style lang="scss">
.tip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 25px 13px;
  min-height: 132px;
  background-image: linear-gradient(
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.738) 19%,
      hsla(0, 0%, 0%, 0.541) 34%,
      hsla(0, 0%, 0%, 0.382) 47%,
      hsla(0, 0%, 0%, 0.278) 56.5%,
      hsla(0, 0%, 0%, 0.194) 65%,
      hsla(0, 0%, 0%, 0.126) 73%,
      hsla(0, 0%, 0%, 0.075) 80.2%,
      hsla(0, 0%, 0%, 0.042) 86.1%,
      hsla(0, 0%, 0%, 0.021) 91%,
      hsla(0, 0%, 0%, 0.008) 95.2%,
      hsla(0, 0%, 0%, 0.002) 98.2%,
      hsla(0, 0%, 0%, 0) 100%
  );
  z-index: 1;

  @media (max-height: 636px) {
    min-height: 112px;
  }
}

.tip__link {
  position: absolute;
  top: 20px;
  flex: none;
  width: 40px;
  height: 40px;

  &:first-child {
    left: 13px;
  }

  &:last-child {
    right: 13px;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.tip__text {
  margin: 0 auto;
  max-width: 270px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  white-space: pre-line;
}
</style>
